/* .popup-content {
    background: #CDFD35 !important;
    padding: 5px 14px !important;
    text-align: center !important;
    border-radius: 15px !important;
  }
  .popup-arrow {
    color: #CDFD35 !important;
  }
  [role='tooltip'].popup-content {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2) !important;
  }
  

  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  } */

/* Carousel */

.slick-next {
  position: absolute !important;
  right: 20px !important;
  top: 50% !important;
  z-index: 9;
  width: 21px !important;
  height: 42px !important;
}

.slick-prev {
  position: absolute !important;
  left: 20px !important;
  top: 50% !important;
  z-index: 9;
  width: 21px !important;
  height: 42px !important;
}

.slick-dots {
  bottom: 10px !important;
}

.slick-dots li {
  margin: 0 !important;
  width: 18px !important;
  height: 18px !important;
  /* margin-right: 2.5px; */
}

.slick-dots li button {
  padding: 2.5px !important;
}

.slick-dots li:hover button:before {
  background-color: #ec008c !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  left: 2px !important;
  top: 2px !important;
  opacity: 0.9 !important;
  /* border: 1px solid #757575; */
  justify-items: center !important;
  background-color: #d1d1d1;

  content: '' !important; /* Create a pseudo-element to style */
  width: 12px !important; /* Adjust the width and height as needed */
  height: 12px !important;
  /* Make them transparent */
  border: 1px solid #757575 !important; /* Add a 2px solid border with #757575 color */
  border-radius: 50% !important; /* Make them round */
}

.slick-dots li.slick-active button:before {
  color: #ec008c !important;
  background-color: #ec008c !important;
}

.new::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  border: 5px solid black;
  padding: 5px 10px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.Toastify__toast-container {
  top: 43% !important;
  /* left: 50%; */
  transform: translate(-50%, -50%);
}

.custom-input::selection {
  height: 3px !important; /* Change the height value as needed */
}

.slick-list {
  max-height: 500px !important;
}
.slick-track {
  max-height: 500px !important;
}
.slick-slide {
  max-height: 500px !important;
}

/* slider thumb button */

/* .custom-checkbox input[type='checkbox']:checked  {
  background-color: #000000; 
} */

.rounded-gradient-border {
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #fd4b9e, #9794d0, #46d59a, #ff8400)
      border-box;
  border-radius: 16px;
}

.rounded-gradient-border-for-button {
  border: 2px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(
        to right,
        #f2006a,
        #f25600,
        #f2bf00,
        #c0f200,
        #00aef2
      )
      border-box;
  border-radius: 5000px;
}

/* checkbox */

.checkboxBlack {
  /* accent-color: #bbbbbb; */
  width: 15px;
  height: 15px;
  /* accent-color: #bbbbbb; */
}

.checkboxBlack[type='checkbox']:checked::before {
  /* Assuming the tick icon is an SVG */
  /* Change the fill color to red */
  fill: red !important; /* Change this to the desired color */
}
/* Styles for the custom checkbox */
.containerCheck {
  position: relative;
  padding-left: 21px; /* Adjust the padding to fit the checkbox */
  cursor: pointer;
}

/* Hide the default checkbox */
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2.25px;
  left: 0;
  height: 16px;
  width: 16px;
  /* background-color: #eee; */
  border: 1px solid #bbbbbb;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
/* .containerCheck:hover .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.containerCheck input:checked ~ .checkmark {
  background-color: #888888; /* This should be overridden when checked */
}

/* Checked state background color */
.containerCheck input:checked ~ .checkmark:after {
  content: '';
  position: absolute;
  display: block;
  border: 3px solid #fff;
  left: 5px;
  top: 1.1px;
  width: 4px;
  height: 9px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.body-no-scroll {
  overflow: hidden;
}

/* scroll globally */

::-webkit-scrollbar {
  background: white;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

/* checkbox whitish border same color 2 */

/* Styles for the custom checkbox */
.containerCheckWhite {
  /* position: relative; */
  padding-left: 21px; /* Adjust the padding to fit the checkbox */
  cursor: pointer;
}

/* Hide the default checkbox */
.containerCheckWhite input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmarkWhite {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 16px;
  width: 16px;
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
/* .containerCheck:hover .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.containerCheckWhite input:checked ~ .checkmarkWhite {
  background-color: #e8e8e8; /* This should be overridden when checked */
}

/* Checked state background color */
.containerCheckWhite input:checked ~ .checkmarkWhite:after {
  content: '';
  position: absolute;
  display: block;
  border: 3px solid #8e8e8e;
  left: 5px;
  top: 1.1px;
  width: 4px;
  height: 9px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Gray Check box */

/* Styles for the custom checkbox */
.containerCheckGray {
  position: relative;
  padding-left: 21px; /* Adjust the padding to fit the checkbox */
  cursor: pointer;
}

/* Hide the default checkbox */
.containerCheckGray input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmarkGray {
  position: absolute;
  top: 2.25px;
  left: 0;
  height: 16px;
  width: 16px;
  /* background-color: #eee; */
  border: 1px solid #888888;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.containerCheckGray input:checked ~ .checkmarkGray {
  background-color: #888888; /* This should be overridden when checked */
}

/* Checked state background color */
.containerCheckGray input:checked ~ .checkmarkGray:after {
  content: '';
  position: absolute;
  display: block;
  border: 3px solid #fff;
  left: 5px;
  top: 1.1px;
  width: 4px;
  height: 9px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* border radius imp 32px */

.borderRadius32px {
  border-radius: 32px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* Global CSS */
/* input::placeholder:after {
  content: '(For Re-Submission)';
  font-size: 5px;
} */

.reactEasyCrop_CropArea {
  box-shadow: none !important;
}
