@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu-link {
    @apply text-primaryGray hover:text-primaryBlack text-sm14 font-semibold leading-5 inline-block mr-[16px] cursor-pointer;
  }

  .blackBtn {
    @apply bg-primaryBlack px-5 py-2.5 rounded-[5000px] text-sm14 text-[#FFFFFF] hover:bg-[#000000] font-medium;
  }

  .outlineBtn {
    @apply border-[2px] border-primaryBlack py-2.5 rounded-[5000px] text-sm14 px-5 font-medium;
  }

  .outlineBtn40h {
    @apply border-[2px] border-primaryBlack h-[40px] flex items-center rounded-[5000px] text-sm14 px-5 font-medium;
  }

  .grayBtn {
    @apply bg-[#d6d6d6] px-5 py-2.5 rounded-3xl text-sm14 text-[#FFFFFF] font-medium;
  }

  .gray30HButton {
    @apply bg-[#888888] hover:bg-primaryBlack rounded-[5000px] text-sm12 text-[#ffffff] font-medium px-[12px] h-[28px] flex items-center;
  }

  .gray30HBorderButton {
    @apply border-[#8e8e8e] border border-[2px]  rounded-[5000px] text-sm12 text-[#757575] font-medium px-[12px] h-[28px] flex items-center;
  }

  .whiteBtn {
    @apply h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[0.125rem];
  }

  .regCard {
    @apply bg-[#ffffff] mt-8 w-regCards h-regCard shadow-regCardShadow rounded-[30px] relative;
  }

  .loginCard {
    @apply bg-[#ffffff] mt-8 w-loginCard h-loginCard shadow-regCardShadow rounded-[30px] text-center;
  }

  .regHeading {
    @apply text-primaryBlack text-sm18;
  }

  .regInput {
    @apply border-solid border-[#D6D6D6] border rounded-3xl px-[0.938rem] py-[0.438rem] outline-none mt-2.5 w-[100%];
  }

  .bullet {
    @apply text-sm11 text-primaryGray text-center;
  }

  .hrLine {
    @apply max-w-[1408px] w-[90%] bg-[#efefef] h-[1px] mx-auto my-[48px];
  }

  .linkSearch {
    @apply text-[13px] font-normal leading-[21px] text-primaryGray;
  }

  .footerHeadings {
    @apply font-medium text-[13px] leading-4 text-[#333333] mb-[10px];
  }

  .footerLinks {
    @apply font-normal text-[12px] text-[#333333] hover:text-[#333333] leading-[19px] hover:text-[#000000];
  }

  .greenBlueButton {
    @apply bg-[#00f9c6] flex items-center h-[28px] rounded-[5000px] text-primaryBlack text-[12px] font-medium  px-[12px];
  }

  .uploadPageData {
    @apply text-[11px] text-primaryGray leading-[14px];
  }

  ::selection {
    /* background-color: #e93f8f; Set the background color of the selected text */
    background-color: #ec008c; /* Set the background color of the selected text */
    color: white; /* Set the text color of the selected text */
  }

  .gridDescription {
    @apply text-[#FFFFFF] text-[12px] leading-[120%] font-normal mb-[2px];
  }

  /* interns code */
  .btn1 {
    @apply w-[147px] h-[40px] hover:bg-lightGray border border-lightGray font-medium text-[15px] hover:text-primaryBlack;
  }

  .btn2 {
    @apply rounded-full text-[12px] font-medium border border-[1px] border-lightGray hover:bg-lightGray hover:text-primaryBlack ml-[6px];
  }

  .btn3 {
    @apply border border-lightGray w-[80px] h-[26px] rounded-full font-medium ml-2 hover:bg-lightGray text-[12px] hover:text-primaryBlack;
  }

  .div {
    @apply w-[159px] h-[200px] border border-[#F8F9F9] text-center pt-[7px] ml-5 rounded-2xl hover:bg-[#F8F9F9] flex-col leading-none;
  }

  .buybtn {
    @apply rounded-3xl border-2 border-primaryGray h-[26px] w-[75px] text-[#F8F9F9]  font-medium text-[12px] mt-[11px] bg-primaryBlack;
  }

  .contributorUploadCount {
    @apply text-[54px] font-extralight leading-[48px] relative top-[2px];
  }

  .contributorUploadtext {
    @apply text-primaryGray text-[11px] leading-[13px];
  }

  /* dropdown css global two text */
  .dropdownTwoGlobalCommon {
    @apply text-[12px] h-[40px] px-[16px] flex items-center justify-between z-50 pb-[2px];
  }
  .dropdownTwoGlobalTrue {
    @apply rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef];
  }
  .dropdownTwoGlobalFalse {
    @apply rounded-[20px] border  border-[#d6d6d6];
  }

  .mTopUpload {
    @apply mt-[16px];
  }

  .contributorTitleStyling {
    @apply w-[100%] flex justify-center pt-[16px] mb-[16px];
  }

  .contributorUploadIconsMainDiv {
    @apply h-[54px] flex items-center;
  }

  .pinkAmount {
    @apply text-[38px] text-pinkColor leading-[1] font-extralight;
  }

  .imageHoverButtonSmall {
    @apply flex items-center px-[8px]  leading-[1] h-[20px] text-primaryGray bg-[#e8e8e8] text-[11px] opacity-[100%] cursor-pointer rounded-[5000px];
  }
}
